// all styles here
require("@/assets/css/bootstrap.min.css");
require("@/assets/css/animate.min.css");
require("@/assets/css/boxicons.min.css");
require("@/assets/css/flaticon.css");
require("@/assets/css/style.css");
require("@/assets/css/responsive.css");

// import './node_modules/react-modal-video/css/modal-video.min.css'
// import 'react-accessible-accordion/dist/fancy-example.css'
// import 'react-image-lightbox/style.css'
